var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "pga-bottom-navigation",
        [
          _c(
            "pga-authorized-content",
            { attrs: { permission: "EditRole" } },
            [_c("pga-edit-button", { on: { click: _vm.Edit } })],
            1
          ),
          _c(
            "pga-authorized-content",
            { attrs: { permission: "EditUser" } },
            [
              _c("pga-edit-button", {
                attrs: {
                  text: "Select Users",
                  icon: "mdi-account-multiple-plus",
                },
                on: { click: _vm.SelectUsers },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "pga-skeleton-loader",
        { attrs: { "is-loaded": _vm.RoleDetails } },
        [
          _c("pga-role-details", {
            model: {
              value: _vm.RoleDetails,
              callback: function ($$v) {
                _vm.RoleDetails = $$v
              },
              expression: "RoleDetails",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }