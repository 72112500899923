var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("pga-data-table", {
    attrs: {
      title: "Users with the role " + _vm.name,
      headers: _vm.headers,
      data: _vm.listUsers,
      loading: _vm.isLoading,
      "item-key": "Id",
      "show-select": "",
    },
    model: {
      value: _vm.selectedUsers,
      callback: function ($$v) {
        _vm.selectedUsers = $$v
      },
      expression: "selectedUsers",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }