var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "pga-bottom-navigation",
        [
          _c(
            "pga-authorized-content",
            { attrs: { permission: "EditRole" } },
            [
              _c("pga-create-button", {
                on: {
                  click: function ($event) {
                    return _vm.Navigate("/roles/create")
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card",
        { attrs: { cols: "12" } },
        [
          _c("pga-roles-table-index", {
            attrs: { "roles-list": _vm.RolesList, "is-loading": _vm.isLoading },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }