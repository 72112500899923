var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("pga-data-table", {
    attrs: {
      title: "Roles",
      headers: _vm.headers,
      data: _vm.RolesList,
      loading: _vm.isLoading,
      "show-expand": "",
    },
    on: { "row-clicked": _vm.rowClicked },
    scopedSlots: _vm._u([
      {
        key: "expand",
        fn: function (props) {
          return [
            _c(
              "td",
              { attrs: { colspan: props.row.headers.length } },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("h2", [_vm._v(" Read Permissions ")]),
                        _vm._l(
                          _vm.permissionsFiltered(
                            props.row.item.Permissions,
                            "Read"
                          ),
                          function (elem, key) {
                            return _c("v-chip", { key: key }, [
                              _vm._v(" " + _vm._s(elem.Code) + " "),
                            ])
                          }
                        ),
                      ],
                      2
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("h2", [_vm._v(" Edit Permissions ")]),
                        _vm._l(
                          _vm.permissionsFiltered(
                            props.row.item.Permissions,
                            "Edit"
                          ),
                          function (elem, key) {
                            return _c("v-chip", { key: key }, [
                              _vm._v(" " + _vm._s(elem.Code) + " "),
                            ])
                          }
                        ),
                      ],
                      2
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("h2", [_vm._v(" Delete Permissions ")]),
                        _vm._l(
                          _vm.permissionsFiltered(
                            props.row.item.Permissions,
                            "Delete"
                          ),
                          function (elem, key) {
                            return _c("v-chip", { key: key }, [
                              _vm._v(" " + _vm._s(elem.Code) + " "),
                            ])
                          }
                        ),
                      ],
                      2
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12", sm: "3" } },
                      [
                        _c("h2", [_vm._v(" Other Permissions ")]),
                        _vm._l(
                          _vm.allOtherPermissions(props.row.item.Permissions),
                          function (elem, key) {
                            return _c("v-chip", { key: key }, [
                              _vm._v(" " + _vm._s(elem.Code) + " "),
                            ])
                          }
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }